import { TCompany } from '../../types/city.type';

export const parseCities = (companies: TCompany[]) => {
  return companies.reduce((acc, company) => {
    const current = acc[company.city];
    if (current) {
      acc[company.city].push(company);
    } else {
      acc[company.city] = [company];
    }

    return acc;
  }, {} as Record<string, TCompany[]>);
};
