import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";

import { blue } from "@ant-design/colors";
import { Box, Skeleton } from "@chakra-ui/react";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";

import { useStore } from "../../stores/index";

import { Typography } from "antd";

export const TimesCells = observer(() => {
  const { workTimes, registerStore } = useStore();

  useEffect(() => {
    if (registerStore.selectedCompany) {
      console.log(
        registerStore.selectedCompany.title,
        registerStore.selectedCompany.id === 146058
          ? registerStore.selectedService?.id_kto
          : registerStore.selectedService?.id
      );

      workTimes.fetchWorkTimes(
        registerStore.selectedCompany.id,
        registerStore.selectedCompany.id === 146058
          ? registerStore.selectedService?.id_kto
          : registerStore.selectedService?.id
      );
    }
  }, [workTimes, registerStore, workTimes.selectedDay]);
  return (
    <Box margin={2} minHeight='64px' display='flex'>
      <Box display='flex' flexGrow={1}>
        {workTimes.timesLoading ? (
          <Skeleton flexGrow={1} />
        ) : workTimes.workingTimes && workTimes.workingTimes?.length > 0 ? (
          <GridTimes background='white' overflowY='auto'>
            {workTimes.workingTimes?.map(date => {
              return (
                <TimeCell
                  key={date.datetime}
                  isActive={Boolean(workTimes.selectedTime === date.datetime)}
                  onClick={() => workTimes.setSelectedTime(date.datetime)}
                >
                  <Typography>{date.time}</Typography>
                </TimeCell>
              );
            })}
          </GridTimes>
        ) : (
          <Typography style={{ margin: "auto" }}>Нет сеансов, доступных для записи</Typography>
        )}
      </Box>
    </Box>
  );
});

const GridTimes = styled(Box)`
  min-height: 64px;
  padding: 1rem;
  background: white;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 64px);
  justify-content: center;
  grid-gap: 1rem;
`;

const TimeCell = styled(Box, { shouldForwardProp: isPropValid })<{
  isActive: boolean;
}>`
  border: 1px solid;
  height: 32px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  user-select: none;
  cursor: pointer;

  :hover {
    background-color: #f5f5f5;
  }

  ${({ isActive: $isactive }) => {
    if ($isactive) {
      return `
        border: 1px solid transparent;
        background-color: ${blue.primary};
        & > article {
          color: white;
        }

        :hover {
          background-color: ${blue.primary};
        }
      `;
    }
  }};
`;
