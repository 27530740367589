import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { getCurrentStep, getStepByNumber } from '../ui/lib/steps';

export const useStep = () => {
  const location = useLocation();

  const [prevStep, step, nextStep] = useMemo(() => {
    const step = getCurrentStep(location.pathname);
    return [getStepByNumber(step.index - 1), step, getStepByNumber(step.index + 1)];
  }, [location]);

  return { prevStep, step, nextStep };
};
