import React from 'react';

import { Box, Heading } from '@chakra-ui/layout';
import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Skeleton } from 'antd';

export const CardTitle = ({ loading }: { loading: boolean }) => {
  return (
    <Skeleton loading={loading} paragraph={{ rows: 0 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Title>Welcome day</Title>
        </Box>
        <Box>
          <StatStyled>
            <StatLabel>Цена</StatLabel>
            <StatNumber>300.00 руб.</StatNumber>
          </StatStyled>
        </Box>
      </Box>
    </Skeleton>
  );
};

const Title = styled(Heading)`
  @media (max-width: 425px) {
    font-size: var(--chakra-fontSizes-lg);
  }

  @media (max-width: 350px) {
    font-size: var(--chakra-fontSizes-md);
  }
`;

const StatStyled = styled(Stat)`
  @media (max-width: 425px) {
    & > dl > dt {
      font-size: var(--chakra-fontSizes-xs);
    }

    & > dl > dd {
      font-size: var(--chakra-fontSizes-lg);
    }
  }

  @media (max-width: 350px) {
    & > dl > dt {
      font-size: var(--chakra-fontSizes-xs);
    }

    & > dl > dd {
      font-size: var(--chakra-fontSizes-md);
    }
  }
`;
